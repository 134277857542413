<div *ngIf="article !== undefined" class="shopping-cart-item">

  <img class="article-image" [src]="article.image" [alt]="article.product">

  <div class="article-name">
    <div class="article-brand">{{ article.brand }}</div>
    <div class="article-product">{{ article.product }}</div>
    <div class="article-price">{{ article.price }} €</div>
    <div class="article-sponso" *ngIf="article.sponso">Produit partenaire - écoresponsable</div>
  </div>

  <div class="article-end">
    <div class="article-grade" [style.color]="gradeColor">{{ article.grade }}</div>
  </div>

</div>

