import { Component, Input, OnInit } from '@angular/core';
import { Article } from '../article';

@Component({
  selector: 'app-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
  styleUrls: ['./shopping-cart-item.component.scss']
})
export class ShoppingCartItemComponent implements OnInit {

  //colors: string[] = ["#62A0FF", "#046CF0", "#004BA9", "#002D66"];
  colors: string[] = ["#00ff42", "#f4ff00", "#ff9d00", "#ff0000"];
  gradeColor: string = "#002D66";

  constructor() {
  }

  @Input()
  article: Article | undefined;

  @Input()
  count: number | undefined;

  ngOnInit(): void {
    switch(this.article?.grade) {
      case "A": {
        this.gradeColor = this.colors[0];
        break;
      }
      case "B": {
        this.gradeColor = this.colors[1];
        break;
      }
      case "C": {
        this.gradeColor = this.colors[2];
        break;
      }
      case "D": {
        this.gradeColor = this.colors[3];
        break;
      }
    }
  }

}
