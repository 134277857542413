<div class="error-message" *ngIf="errorMessage">
  {{ errorMessage }}
</div>

<div id="scanner-container">
  <!-- QuaggaJS will populate this element -->
</div>

<div class="control">
  <h2>Ethiqu’ette</h2>
  <hr>
  <form>
    <div class="control-head">
      <div><h3>Scanner des articles</h3></div>
      <div><button mat-icon-button color="warn" (click)="clearCart()"><mat-icon>delete</mat-icon></button></div>
    </div>
  </form>
  <div class="shopping-cart">
    <span *ngIf="items.length === 0 else shoppingCartItems"> </span>
    <ng-template #shoppingCartItems>
      <app-shopping-cart-item
        *ngFor="let articleAndCount of items"
        [article]="articleAndCount[0]"
        [count]="articleAndCount[1]"
        (click)="openDetails(articleAndCount[0])" >
      </app-shopping-cart-item>
    </ng-template>
  </div>
</div>
