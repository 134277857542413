import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Article} from "../article";

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  items: Article[] = [];
  totalPrice: number = 0;
  //colors: string[] = ["#62A0FF", "#046CF0", "#004BA9", "#002D66"];
  colors: string[] = ["#75ff00", "#fffb00", "#ff9d00", "#ff0000"];
  gradeColor: string = "#002D66";

  private catalogue: Article[] = [
    { sponso: false, brand: 'Adidas', product: 'Chaussure Superstar', color:'Blanche', grade: 'C', website: 'https://www.adidas.fr/chaussure-superstar/EG4958.html', ean: '1234567891118', image: 'assets/adidas.png', price: 110 },
    { sponso: false, brand: 'Nike', product: 'Air Force 1', color:'Blanche', grade: 'D', website: 'https://www.nike.com/fr/t/chaussure-air-force-1-pour-plus-age-65f76v/CT3839-100', ean: '1234567891125', image: 'assets/nike.png', price: 95 },
    { sponso: true, brand: 'Veja', product: 'ESPLAR LEATHER', color:'Blanche', grade: 'A', website: 'https://www.veja-store.com/fr_fr/esplar-leather-white-black-eo020005.html', ean: '1234567891132', image: 'assets/veja.png', price: 120 },
  ];

  constructor(public dialogRef: MatDialogRef<ProductDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Article, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.catalogue.forEach(item => {
      if(item.grade < this.data.grade && item.ean != this.data.ean) {
        this.items.push(item);
      }
    });

    this.items.sort((a, b) => {
      if (a.grade < b.grade) return -1;
      else if (a.grade > b.grade) return 1;
      else return 0;
    });

    switch(this.data.grade) {
      case "A": {
        this.gradeColor = this.colors[0];
        break;
      }
      case "B": {
        this.gradeColor = this.colors[1];
        break;
      }
      case "C": {
        this.gradeColor = this.colors[2];
        break;
      }
      case "D": {
        this.gradeColor = this.colors[3];
        break;
      }
    }
  }

  openDetails(articleDetails:Article): void {
    this.dialog.open(ProductDetailsComponent, {
      data: articleDetails,
      disableClose: true,
      autoFocus: true,
      position: {
        top: '0',
        left: '0'
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
