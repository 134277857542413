<h1 mat-dialog-title>{{ data.brand }} - {{ data.product }}</h1>
<div mat-dialog-content>
  <div class="core-details">
    <div class="image"><img class="article-image" [src]="data.image" [alt]="data.product"></div>
    <div class="details">
      <div class="container-left">
        <div class="article-brand">{{ data.brand }}</div>
        <div class="article-product">{{ data.product }}</div>
        <div class="article-price">{{ data.price }} €</div>
      </div>
      <div class="container-right">
        <div class="article-grade" [style.color]="gradeColor">{{ data.grade }}</div>
      </div>
    </div>
    <div class="more-details" *ngIf="data.grade != 'D'">
      <hr>
      <a href="{{ data.website }}" target="_blank"><div class="article-link"><div><mat-icon class="icon">link</mat-icon></div><div> Site web</div></div></a>
      <a href="https://www.google.com/maps/search/{{ data.brand }}+le+plus+proche/" target="_blank"><div class="article-link"><div><mat-icon class="icon">pin_drop</mat-icon></div><div> {{ data.brand }} le plus proche de moi</div></div></a>
    </div>
    <hr>
    <div class="shopping-cart">
      <span *ngIf="items.length === 0 else shoppingCartItems"> </span>
      <ng-template #shoppingCartItems>
        <h2>Alternatives</h2>
        <app-shopping-cart-item
          *ngFor="let articleAndCount of items"
          [article]="articleAndCount"
          [count]="1"
          (click)="openDetails(articleAndCount)" >
        </app-shopping-cart-item>
      </ng-template>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Fermer</button>
</div>
